<template>
    <div class="cashierTradeNoteDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="85px"
                >
                    <el-col :span="6">
                        <el-form-item label="机构">
                            <el-input v-model="form.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="交易流水号">
                            <el-input v-model="form.tradeNo" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isRefund">
                        <el-form-item label="退货流水号">
                            <el-input v-model="form.refundNo" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="时间">
                            <el-date-picker v-model="form.time" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="收银员">
                            <el-input v-model="form.cashier" style="width: 230px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isRefund">
                        <el-form-item label="操作人">
                            <el-input v-model="meta.pageData.refundCashier" style="width: 230px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isRefund">
                        <el-form-item label="应退金额">
                            <el-input v-model="meta.pageData.shouldRefundMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isRefund">
                        <el-form-item label="退回优惠">
                            <el-input v-model="meta.pageData.refundFavourMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isRefund">
                        <el-form-item label="实退金额">
                            <el-input v-model="meta.pageData.money" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="总金额">
                            <el-input v-model="meta.pageData.goodsMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="优惠">
                            <el-input v-model="meta.pageData.goodsFavour" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="整单优惠">
                            <el-input v-model="meta.pageData.tradeFavour" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="抹零">
                            <el-input v-model="meta.pageData.floor" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="实收">
                            <el-input v-model="meta.pageData.money" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="已退总额">
                            <el-input v-model="meta.pageData.refundMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!isRefund">
                        <el-form-item label="剩余总额">
                            <el-input v-model="meta.pageData.tradeMoney" />
                        </el-form-item>
                    </el-col>
                    <el-row />
                </el-form>
                <el-card style="margin-top: 8px">
                    <el-table
                        id="print"
                        border
                        stripe
                        style="width: 100%"
                        :data="meta.PayData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="500"
                    >
                        <el-table-column prop="payName" label="支付类型" width="180" />
                        <el-table-column label="金额" width="180">
                            <template slot-scope="scope">
                                <span>{{ scope.row.money | money }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card style="margin-top: 8px">
                    <el-table
                        id="printMe"
                        border
                        stripe
                        style="width: 100%"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="500"
                    >
                        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                        <el-table-column prop="goodsName" label="商品名称" width="180" />
                        <el-table-column prop="bar" label="条码" width="180" />
                        <el-table-column prop="specs" label="规格" width="130" />
                        <el-table-column prop="unit" label="单位" width="80" />
                        <el-table-column label="数量" width="120" prop="count" />
                        <el-table-column v-if="!isRefund" label="赠送数量" width="120" prop="giftCount" />
                        <el-table-column label="原价" width="120">
                            <template slot-scope="scope">
                                <span>{{ scope.row.price | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额" width="160">
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsMoney | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="优惠金额" width="160" v-if="!isRefund">
                            <template slot-scope="scope">
                                <span>{{ tradeGoodsFavour(scope.row) | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="小计" width="160" v-if="!isRefund">
                            <template slot-scope="scope">
                                <span>{{ scope.row.money | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="优惠扣款" width="160" v-if="isRefund">
                            <template slot-scope="scope">
                                <span>{{ scope.row.favourMoney | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="应退金额" width="160" v-if="isRefund">
                            <template slot-scope="scope">
                                <span>{{ scope.row.needRefund | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="实退金额" width="160" v-if="isRefund">
                            <template slot-scope="scope">
                                <span>{{ scope.row.money | money }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';

export default {
    name: 'CashierTradeNoteDetail',
    props: ['form'],
    data() {
        return {
            url: {
                queryGoods: '/goods/goods/list_c',
                queryNoteDetail: '/report/cash/noteDetail',
            },
            query: {
                type: this.form.type,
                tradeCode: this.form.tradeCode,
                refundCode: this.form.refundCode,
            },
            meta: {
                pageData: {},
                PayData: [],
            },
            isRefund: this.form.type != 1,
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        tradeGoodsFavour(row) {
            return row.goodsMoney - row.money;
        },
        handleQuery() {
            const _this = this;
            const noteParams = { params: { tradeCode: _this.form.tradeCode, refundCode: _this.form.refundCode } };
            Util.queryTable(_this, _this.url.queryNoteDetail, noteParams, (res) => {
                _this.meta.PayData = res.data.pays;
                _this.meta.pageData.refundCashier = res.data.refundCashier;
                _this.meta.pageData.money = _this.fmtMoney(res.data.money);
                _this.meta.pageData.refundMoney = _this.fmtMoney(res.data.refundMoney);
                _this.meta.pageData.goodsMoney = _this.fmtMoney(res.data.goodsMoney);
                _this.meta.pageData.goodsFavour = _this.fmtMoney(res.data.goodsFavour);
                _this.meta.pageData.tradeFavour = _this.fmtMoney(res.data.tradeFavour);
                _this.meta.pageData.floor = _this.fmtMoney(res.data.floor);
                _this.meta.pageData.refundFavourMoney = _this.fmtMoney(res.data.refundFavourMoney);
                _this.meta.pageData.shouldRefundMoney = _this.fmtMoney(res.data.shouldRefundMoney);
                _this.meta.pageData.tradeMoney = _this.fmtMoney(res.data.tradeMoney);
                if (res.data.goodsCodes) {
                    const _params = {
                        params: res.data.goodsCodes,
                        paramsSerializer: function (data) {
                            return data.map((d) => 'codes=' + d).join('&');
                        },
                    };
                    Util.queryTable(_this, _this.url.queryGoods, _params, (data) => {
                        data.data.forEach((v, i) => {
                            res.data.details[i].goodsName = v.sku.name;
                            res.data.details[i].specs = v.sku.specs;
                            res.data.details[i].bar = v.sku.bars.join(' / ');
                            res.data.details[i].unit = v.sku.unit;
                        });
                        _this.tableData = res.data.details;
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
